<template>
  <div class="pdf-container">
    <iframe src="/mou.pdf" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  // No need for data() or require('@/assets/mou.pdf')
};
</script>

<style scoped>
.pdf-container {
  width: 100%;
  height: 100vh; /* Viewport height */
  position: relative; /* Needed for iframe to calculate height correctly */
}
</style>